<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    this.$router.replace(
      {
        name: "uploadFile",
      },
      () => {}
    );
  },
};
</script>

<style>
.el-upload {
  width: 100%;
  height: 100%;
}
</style>
