import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    info: {
      terminalId: '',
      phone: ''
    }
  },
  getters: {
  },
  mutations: {
    setInfo(state, info){
      state.info = info;
    },
  },
  actions: {
  },
  modules: {
  }
})
